/**
 * Print Stylesheet for TAFESA.edu.au
* @version         1.0
* @lastmodified    16.06.2016
*/


  
  @media print {
      
      html, body {
        width: 210mm;
        height: 297mm;
      }
      /* Setting content width, unsetting floats and margins */
    /* Attention: the classes and IDs vary from theme to theme. Thus, set own classes here */
    .mainContent {
        width: 100%;
        margin: 0;
        float: none !important;
        padding: 0;
    }

    /** Setting margins */
    @page {
        size: A4;
        margin: 2cm;
    }

    /* Set font to 16px/13pt, set background to white and font to black.*/
    /* This saves ink */
    body {
        font: 13pt Georgia, "Times New Roman", Times, serif;
        line-height: 1.3;
        background: #fff !important;
        color: #000;
    }

    h1 {
        font-size: 24pt;
    }

    h2,
    h3,
    h4 {
        margin-top: 25px;
    }

    h2 {
        font-size: 18pt;
    }

    h3,
    h4 {
        font-size: 16pt;
    }

    /* Defining all page breaks */
    a {
        page-break-inside: avoid
    }

    blockquote {
        page-break-inside: avoid;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
        page-break-inside: avoid
    }

    img {
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    table,
    pre {
        page-break-inside: avoid
    }

    ul,
    ol,
    dl {
        page-break-before: avoid
    }

    /* Displaying link color and link behaviour  */
    a:link,
    a:visited,
    a {
        background: transparent;
        color: #520;
        font-weight: bold;
        text-decoration: underline;
        text-align: left;
    }

    a {
        page-break-inside: avoid
    }

    a[href^=http]:after {
        content: " <"attr(href) "> ";
    }



    article a[href^="#"]:after {
        content: "";
    }

    a:not(:local-link):after {
        content: " <"attr(href) "> ";
    }

    /**
   * Making intergated videos disappear, and removing the iframes' whitespace to zero. 
   */
    .entry iframe,
    ins {
        display: none;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        line-height: 0pt !important;
        white-space: nowrap;
    }

    .embed-youtube,
    .embed-responsive {
        position: absolute;
        height: 0;
        overflow: hidden;
    }

    /* Hiding unnecessary elements for the print */

    #header-widgets,
    nav,
    aside.mashsb-container,
    .sidebar,
    .mashshare-top,
    .mashshare-bottom,
    .content-ads,
    .make-comment,
    .author-bio,
    .heading,
    .related-posts,
    #decomments-form-add-comment,
    #breadcrumbs,
    #footer,
    .post-byline,
    .meta-single,
    .site-title img,
    .post-tags,
    .readability {
        display: none;
    }

    //   .mainContent:after {
    //   content: "\ All Rights Reserved. 2018 TAFE SA";
    //   color: #999 !important;
    //   font-size: 1em;
    //   padding-top: 30px;
    //   }
    //   .mainContent:before {
    //   content: "\ Thank you for printing this page. For the most up-to-date information please visit the website.";
    //   color: #777 !important;
    //   font-size: 1em;
    //   padding-top: 30px;
    //   text-align: center !important;    
    //   }

    p,
    address,
    li,
    dt,
    dd,
    blockquote {
        font-size: 100%
    }

    code,
    pre {
        font-family: "Courier New", Courier, mono
    }

    ul,
    ol {
        list-style: square;
        margin-left: 18pt;
        margin-bottom: 20pt;
    }

    li {
        line-height: 1.6em;
    }

    #content,
    .collapse {
        display: block !important;
        height: auto !important;
    }

    body {
        background-color: transparent !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 11pt !important;
        color: black !important;
        margin-top: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;
    }

    #navbarHeader,
    #printCSSLogo {
        display: block !important;
        position: absolute;
        top: 0;
    }

    a.logo, #tafegosa {
        display: none !important;
    }

    #UNITS {
        display: block !important;
    }

    #footerLinks,
    #menuFooterLinks,
    #navbarNavigation,
    #navbarQuickLink {
        display: none !important;
    }

    a[href]:after {
        content: "" !important;
    }

    #navbar,
    #navbarMain {
        position: absolute !important;
    }

    .top,
    .video-container,
    .carousel,
    .moreNews,
    .mainCourseSearch,
    #tafeCounter,
    .menuFooter,
    .footer_bottom-logos {
        display: none !important;
    }

    .menuFooter, .footer{
        padding: 0 !important;
        float: none !important;
    }

    .footer_bottom{
        display: block !important;
    }

    .img-responsive {
        max-width: 300px !important;
        page-break-inside: avoid;
    }

    .title {
        padding-bottom: 60px !important;
    }

    .aboutImage {
        page-break-inside: avoid;
    }

    .mainBanner .banner-image {
        height: 140px !important;
    }

    a.btn_applynow.btn_cp,
    button.btn_cp,
    hr.sc_div,
    .cp_spacer,
    .cp_footer-top,
    #testimonial-heading,
    .no_print,
    .studystats,
    .study_selector,
    #themebanner,
    #background,
    .image_half-buffer,
    .employee-outcomes>ul>li>a>h2,
    .employee-outcome>ul>li>a>h2,
    .pathways-block>ul>li>a>h2,
    .pathways-block,
    .marketing_api,
    .cp_video-holder,
    .workready,
    .elevator-button {
        display: none !important;
    }

    body {
        padding-top: 85px !important;
    }

    section.breadcrumbs-nav,
    section.mainBanner {
        display: none !important;
    }

    .cp_table {
        .heading_row {
            height: auto;
            min-height: 0;

            .cp_cell {
                background-color: #777;
                border: #EEEEEE solid 1px;
            }

        }

        .cp_table_row {
            height: auto;
            min-height: 30px;
            margin-top: 0;
            margin-bottom: 0;

            .cp_cell {
                padding: 16px 10px;
                min-height: 58px !important;
                max-height: 58px !important;
                border: #EEEEEE solid 1px;
                line-height: 1 !important;
            }


        }
    }

    .cp_table-wrapper {
        .heading_row {
            height: auto;
            min-height: 0;

            .cp_cell {
                background-color: #EEEEEE !important;
                border: #EEEEEE solid 1px !important;
            }
        }

        .cp_cd_row {
            height: auto;
            min-height: 30px;
            margin-top: 0;
            margin-bottom: 0;

            .cp_cell {
                padding: 16px 10px;
                min-height: 58px !important;
                max-height: 58px !important;
                border: #EEEEEE solid 1px;
                line-height: 1 !important;
                width: 20% !important;
            }

            .cp_cell:last-child {
                display: none !important;
            }
        }
    }

    .help-fee_flex {
        // flex-direction: column !important;
        display: block !important;
    }

    #content {
        padding: 0 !important;

        .des_half-buffer {
            width: 100% !important;
        }
    }

    .cp_heading::before {
        content: '' !important;
    }

    ul.accordion-section h2.accordian_link {
        margin-top: 32pt;
        padding: 0 !important;
    }

    .help-fee_wrapepr {
        min-height: 0 !important;
    }

    .cp_cell.align-R {
        text-align: left;
        margin-right: 0;
    }

    .cp_cell.align-L {
        text-align: left !important;
        line-height: 1;
        min-height: 30px;

        br {
            display: none;
        }
    }

    .info-session>.cp_cell:nth-child(3),
    .info-session>.cp_cell:nth-child(4) {
        display: none !important;
    }

    .info-session {
        justify-content: flex-start;

        .cp_cell.align-L {
            flex-grow: 3;
        }

        .cp_cell.align-R {
            margin-right: 0;
        }
    }

    .cp_summary_row {
        .cp_cell:first-child {
            min-height: 61px;
        }
    }

    .mainContent {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .mainContent ul li {
        padding: 0 !important;
    }

    .heading_row .cp_cell,
    .cp_feesTable-row>.cp_cell:first-child:not(#duration) {
        line-height: unset !important;
    }

    .header_inner .navbar-header {
        padding-left: 0 !important;
    }

    .cp_summary {
        border-bottom: none !important;
    }

    .mainContent table td,
    .tdgrid {
        padding: 0 !important;
    }

    #delivery {
        display: block !important;
    }

    .ft_table-row{
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        // [class*="col-"]:not(.hide_mobile):not(.empty){
        //     width: 100%;
        // }
          
        .ft_table-duration{
           display: flex;
           flex-direction: row;
           width: 100%;
        
           h5{
                line-height: 1 !important;
           }    
        }

        .ft_cell.col-sm-6{
            width: 30%;
        }

    }

    .ft_table-wrapper .ft_table-sub-wrapper{
        margin: 0 !important;
        padding: 0 !important;
    }

    .ft_table-funded p.note, .ft_table-note {
        width: 100%;
    }
    
    .howToApply{
        margin: 32px 0;
    }


}